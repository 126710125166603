import {
  ColumnDef,
  ColumnOrderState,
  ColumnSizingState,
  SortingState,
} from "@tanstack/react-table";
import { ACTION_COLUMN_DEF_CONSTANTS } from "../ActionCell";
import { uniqBy } from "lodash";

export const sanityCheckLocalColumnOrder = ({
  localColumnOrder,
  defaultColumnIds,
}: {
  localColumnOrder: Maybe<ColumnOrderState>;
  defaultColumnIds: Array<string>;
}): Maybe<ColumnOrderState> => {
  if (!localColumnOrder) {
    return null;
  }

  if (localColumnOrder.some(columnId => !defaultColumnIds.includes(columnId))) {
    return defaultColumnIds;
  }

  //re-insert hidden column ids before action column on refresh
  if (localColumnOrder.length < defaultColumnIds.length) {
    const missingColumnIds = defaultColumnIds.filter(
      defaultColumnId => !localColumnOrder.includes(defaultColumnId!)
    );

    return [
      ...localColumnOrder.filter(
        columnId => columnId !== ACTION_COLUMN_DEF_CONSTANTS.id
      ),
      ...missingColumnIds,
      ACTION_COLUMN_DEF_CONSTANTS.id,
    ];
  }

  if (
    localColumnOrder[localColumnOrder.length - 1] !==
    ACTION_COLUMN_DEF_CONSTANTS.id
  ) {
    return [
      ...localColumnOrder.filter(
        columnId => columnId !== ACTION_COLUMN_DEF_CONSTANTS.id
      ),
      ACTION_COLUMN_DEF_CONSTANTS.id,
    ];
  }

  return null;
};

export const sanityCheckColumnSizing = <T,>({
  columnSizing,
  columns,
}: {
  columnSizing: ColumnSizingState;
  columns: ColumnDef<T>[];
}) => {
  const possibleColumnIds = columns.map(column => column.id!);
  if (
    Object.keys(columnSizing).some(
      columnId => !possibleColumnIds.includes(columnId)
    )
  ) {
    return {};
  }

  return null;
};

export const sanityCheckSorting = <T,>({
  sorting,
  columns,
}: {
  sorting?: SortingState;
  columns: ColumnDef<T>[];
}) => {
  const possibleColumnIds = columns.map(column => column.id!);

  if (
    sorting?.some(
      sortDefinition => !possibleColumnIds.includes(sortDefinition.id)
    )
  ) {
    return [];
  }

  return null;
};

export const fieldId = (field: { table: string; name: string }) =>
  `${field.table}.${field.name}`;

export const mergeFieldGroups = (
  fields: Array<{ name: string }>,
  requiredFields: Array<{ name: string }>
) => uniqBy([...fields, ...requiredFields], "name");

export const splitTableAndColumnNames = (key: string) => {
  const splitKey = key.split(".");

  return {
    table: splitKey[0],
    name: splitKey[1],
  };
};

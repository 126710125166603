import React, { useContext, useState } from "react";

import { buildLink, pipLink } from "common/routing";
import { ActionsProps } from "../Inputs/DropdownMenu";
import { GetDocumentUploadsQuery } from "../../generated/graphql";
import { AuthContext } from "../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { createTracker, track } from "../../utils/tracking";
import { useModal } from "react-modal-hook";
import { DeleteDocumentUploadConfirmation } from "./DeleteConfirmation";
import Modal from "../Common/Modal";
import { useIssueModal } from "../Issues";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { Button } from "../Common/Button";
import { useShareFileModal } from "./ElevationCertificates/ShareFileModal";
import { Actions } from "../Common/ActionCell";

export interface ActionButtonProps {
  prevLocation?: string;
  isDetailView?: boolean;
  disabled?: boolean;
  httpClient?: typeof fetch;
  onUpdate?: () => void;
  documentUpload: Omit<
    GetDocumentUploadsQuery["documentUploads"]["data"][number],
    "url" | "name" | "property" | "elevationCertificate"
  > & {
    property?: Maybe<{
      id: string;
      streetAddress?: Maybe<string>;
      longitude?: Maybe<number>;
      latitude?: Maybe<number>;
    }>;
    elevationCertificate?: Maybe<{
      id: string;
      issuedAt?: any | null;
      certfierInfo?: {
        name: Maybe<string>;
        email: Maybe<string>;
      };
    }>;
  };
}

const ActionButton = ({
  documentUpload,
  prevLocation,
  isDetailView = false,
  disabled = false,
  httpClient = fetch,
  onUpdate,
}: ActionButtonProps) => {
  const {
    property,
    elevationCertificate,
    issuedAt,
    accountDocumentType,
    id: documentUploadId,
    originalFilename,
    formData,
    submission,
    hiddenFromPublic,
  } = documentUpload;

  const coordinates = [property?.longitude!, property?.latitude!] as const;

  const [loadingDocxExport, setLoadingDocxExport] = useState(false);
  const { addFailureToast } = useStatusToasts();

  const [showIssueModal] = useIssueModal({
    address: property?.streetAddress ?? "",
    coordinates,
    certificateId: elevationCertificate?.id,
  });

  const [showShareFileModal] = useShareFileModal({
    documentUpload,
  });

  const [showDeleteDocumentUploadModal, hideDeleteDocumentUploadModal] =
    useModal(() => (
      <Modal onRequestClose={hideDeleteDocumentUploadModal}>
        <DeleteDocumentUploadConfirmation
          isDetailView={isDetailView}
          issuedAt={issuedAt}
          accountDocumentType={accountDocumentType}
          documentUploadId={documentUploadId}
          closeModal={hideDeleteDocumentUploadModal}
          onUpdate={onUpdate}
          address={property?.streetAddress ?? null}
          propertyId={property?.id ?? null}
        />
      </Modal>
    ));

  const { account, authorized, isGuest } = useContext(AuthContext);
  const canShowReportDataIssue = authorized({
    resource: RESOURCE_NAME.ISSUE,
    permission: "create",
  });

  const canViewDocumentDetail = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "view_details",
  });

  const canDeleteDocumentUpload = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "delete",
  });

  const showAdminButton = authorized({
    resource: RESOURCE_NAME.CERTIFICATE,
    permission: "updateSettings",
  });

  const canShareDocumentUpload = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "share",
  });

  const viewLocation = canViewDocumentDetail
    ? {
        to: {
          pathname: buildLink(
            isGuest ? "guestDocumentUploadDetail" : "documentUploadDetail",
            {
              id: documentUpload.id,
            }
          ),
          state: { prevLocation },
        },
      }
    : {
        href: buildLink(
          isGuest ? "guestDocumentUploadFile" : "documentUploadFile",
          {
            id: documentUpload.id,
          }
        ),
        target: "_blank",
      };

  const adminSettingsAction: ActionsProps = {
    label: "Admin Settings",
    to: {
      pathname: `/backoffice/certificates/${elevationCertificate?.id}`,
    },
    target: "_blank",
  };

  const deleteAction: ActionsProps = {
    label: "Delete",
    onClick: () => showDeleteDocumentUploadModal(),
    disabled: !canDeleteDocumentUpload,
    variant: "red",
  };

  const handleClickExportDocx = async () => {
    setLoadingDocxExport(true);

    track("Clicked 'Download .docx'", {
      documentType: accountDocumentType.name,
      propertyAddress: property?.streetAddress,
    });

    const failureToastText =
      "There was a problem with your download. Please try again or reach out to support@withforerunner.com for help.";

    try {
      const response = await httpClient(
        `/api/files/docx-export/${documentUploadId}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        addFailureToast(failureToastText);
        return;
      }

      const blobObject = await response.blob();
      const url = URL.createObjectURL(blobObject);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${originalFilename.split(".")[0]}.docx`;
      a.click();
      URL.revokeObjectURL(url);
    } catch {
      addFailureToast(failureToastText);
    }

    setLoadingDocxExport(false);
  };

  const exportDocxAction: ActionsProps = {
    label: "Download .docx",
    onClick: handleClickExportDocx,
  };

  let downloadAction: ActionsProps = {
    label: "Download",
    href: buildLink("documentUploadFile", {
      id: documentUpload.id,
    }),
    download: true,
    target: "_blank",
  };

  const reportDataIssueAction: ActionsProps = {
    label: "Report data issue",
    onClick: () => showIssueModal(),
    disabled: !canShowReportDataIssue,
    closeOnClick: false,
  };

  const shareFileAction: ActionsProps = {
    label: "Share",
    disabled: !canShareDocumentUpload,
    onClick: () => {
      track("Clicked 'Share'", { documentUploadId });
      showShareFileModal();
    },
  };

  const viewAction: ActionsProps = {
    label: "View",
    onClick: createTracker("View File clicked"),
    ...viewLocation,
  };

  const viewOnMapAction: ActionsProps = {
    label: "View on Map",
    to: pipLink({
      address: property?.streetAddress ?? "",
      propertyId: property?.id,
      lat: property?.latitude?.toString() ?? "",
      lng: property?.longitude?.toString() ?? "",
    }),
  };

  const actions: Array<ActionsProps> = [];
  if (!isDetailView) {
    actions.push(viewAction);
  } else {
    if (property) {
      if (isGuest) {
        actions.push({
          label: "View property",
          href: buildLink("residentProperty", {
            propertyId: property.id,
          }),
        });
      } else {
        actions.push(viewOnMapAction);
      }
    }
    if (elevationCertificate && !hiddenFromPublic && !isGuest) {
      if (account?.publicPortal.enabled) {
        actions.push(shareFileAction);
      }
      actions.push(reportDataIssueAction);

      if (showAdminButton) {
        actions.push(adminSettingsAction);
      }
    }
    if (formData) {
      downloadAction.label = "Download .pdf";
      actions.push(exportDocxAction);
      actions.push(downloadAction);
    }
  }

  if (!(isDetailView && formData)) {
    actions.push(downloadAction);
  }

  if (!submission && !isGuest) {
    actions.push(deleteAction);
  }

  const actionButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <Button
      onClick={onClick}
      styleVariant="outlineLight"
      rightIconName="chevron-down"
      size="small"
      disabled={loadingDocxExport}
      loading={loadingDocxExport}
      data-testid="actions"
    >
      Actions
    </Button>
  );

  return (
    <Actions
      buttonText={isDetailView ? "Actions" : undefined}
      disabled={disabled}
      actions={actions}
      customButton={isDetailView ? actionButton : undefined}
    />
  );
};

export default ActionButton;

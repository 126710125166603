import {
  FIRM_WARNING_NAME,
  FirmWarningTextConfig,
  FIRMWithWarnings,
  floodzoneWarningText,
  getFirmWarning as getFirmWarningText,
  renderFirmWarning,
  PlaceholderToComponentMap,
} from "common-client/utils/firmInfoWarnings";
import { BannerItems } from "./__styles__/FIRMInfo";
import { RESOURCE_NAME } from "common/authorization";
import { useContext } from "react";
import { AuthContext } from "../../../Authorization/AuthContext";
import { getFormattedFIRMNames } from "common-client/utils/firms";
import { flatten, uniq } from "lodash";
import React from "react";
import { StyledLink } from "../../../Common/__styles__/Typography";
import { GetHelpLink } from "../../../Guest/GetHelpLink";
import Banner from "../../../Common/Banner";

export const FirmWarningsBanner = ({
  firms,
  propertyId,
  warningTextConfig,
  title,
  additionalReplacements,
  compactList = false,
}: {
  firms: Array<
    Pick<FIRMWithWarnings, "warnings" | "isApproximateBfe" | "name" | "mscLink">
  >;
  propertyId?: string;
  warningTextConfig: FirmWarningTextConfig;
  title?: string;
  additionalReplacements?: PlaceholderToComponentMap;
  compactList?: boolean;
}) => {
  const { authorized, isGuest } = useContext(AuthContext);

  const warningNames = uniq(
    flatten(firms.map(firm => firm.warnings)).map(warning => warning.name)
  );

  const canUpdateFIRMInfo = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "update",
  });

  const canViewFIRMInfo = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "view",
  });

  const mscLink = firms.find(firm =>
    firm.warnings.some(
      warning => warning.name === FIRM_WARNING_NAME.PROPERTY_HAS_APPROXIMATE_BFE
    )
  )?.mscLink;

  const warningsInBanner = uniq(warningNames).map(warningName => {
    const formatedFirmNames = getFormattedFIRMNames(firms, warningName);

    const floodzoneText = floodzoneWarningText(firms);

    return getFirmWarningText({
      firmWarning: warningName,
      canUpdate: canUpdateFIRMInfo,
      isGuest,
      isViewOnly: canViewFIRMInfo && !isGuest,
      placeholderToComponentMap: {
        "{GET_HELP_LINK}": <GetHelpLink propertyId={propertyId} />,
        "{FLOODZONE_TEXT}": <>{floodzoneText}</>,
        "{FIRM_NAME}": <strong>{formatedFirmNames}</strong>,
        "{MSC_LINK}": (
          <StyledLink href={mscLink!} target="_blank">
            Flood Insurance Study
          </StyledLink>
        ),
        ...additionalReplacements,
      },
      renderWarning: renderFirmWarning,
      warningTextConfig,
    });
  });

  if (!warningsInBanner.length) return null;

  return (
    <Banner
      compact={true}
      warning={true}
      style={{ backgroundImage: "none", padding: "12px 16px" }}
    >
      {title && <div style={{ fontWeight: 500 }}>{title} warnings</div>}
      {warningsInBanner.map((warning, i) => (
        <BannerItems compact={compactList} key={i}>
          {warning}
        </BannerItems>
      ))}
    </Banner>
  );
};

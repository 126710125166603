import { useModal } from "react-modal-hook";
import Modal from "../../Common/Modal";
import React, { useContext, useState } from "react";

import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
  SecondaryButtons,
} from "../../Common/__styles__/Modal";
import { Button } from "../../Common/Button";
import { useForm } from "react-hook-form";
import { Checkbox, Select, Text, Textarea } from "../../Inputs/react-hook-form";
import { FlexColumn, FlexRow } from "../../Common/Layout";
import { AuthContext } from "../../Authorization/AuthContext";
import { buildGuestDocumentUploadDetailURL } from "common/utils/url";
import { track } from "../../../utils/tracking";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import {
  InformationRequestContactType,
  useShareDocumentUploadMutation,
} from "../../../generated/graphql";
import { SuccessView } from "../../Common/ShareItem/SuccessView";
import { CONTACT_TYPE_OPTIONS } from "../../Common/ShareItem/utils";
import { isValidEmail } from "common/utils/strings";

type ShareFileModalProps = {
  onCancel: () => void;
  documentUpload: {
    id: string;
    originalFilename: string;
    property?: Maybe<{ id: string }>;
    elevationCertificate?: Maybe<{
      id: string;
      certifierInfo?: {
        name: Maybe<string>;
        email: Maybe<string>;
      };
    }>;
  };
};

export const useShareFileModal = ({
  ...props
}: Omit<ShareFileModalProps, "onCancel">) => {
  const [showShareFileModal, closeShareFileModal] = useModal(() => (
    <Modal onRequestClose={closeShareFileModal}>
      <ShareFileModal onCancel={closeShareFileModal} {...props} />
    </Modal>
  ));

  return [showShareFileModal, closeShareFileModal] as const;
};

type ShareFileFormStructure = {
  name: string;
  role: InformationRequestContactType;
  email: string;
  message?: Maybe<string>;
  copyYourself: boolean;
  createLog: boolean;
};

export const ShareFileModal = ({
  clipboard = navigator.clipboard,
  documentUpload,
  onCancel,
}: ShareFileModalProps & {
  clipboard?: Pick<typeof navigator.clipboard, "writeText">;
}) => {
  const { account, admin, user } = useContext(AuthContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [residentLogId, setResidentLogId] = useState<Maybe<string>>(null);
  const { addFailureToast, addSuccessToast } = useStatusToasts();

  const surveyorName =
    documentUpload.elevationCertificate?.certifierInfo?.name || undefined;
  const surveyorEmail =
    documentUpload.elevationCertificate?.certifierInfo?.email || undefined;

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<ShareFileFormStructure>({
    defaultValues: {
      role:
        surveyorEmail || surveyorName
          ? InformationRequestContactType.SURVEYOR
          : undefined,
      name: surveyorName,
      email: surveyorEmail,
    },
  });

  const [shareDocumentUpload, { loading }] = useShareDocumentUploadMutation({
    onCompleted: response => {
      setResidentLogId(response.shareDocumentUpload.log?.id ?? null);
      setShowSuccess(true);
    },
    onError: () => {
      addFailureToast(
        "There was an error sharing this file. Please try again. If the problem persists, please email us at support@withforerunner.com"
      );
    },
  });

  const fileURL = buildGuestDocumentUploadDetailURL({
    subdomain: account!.publicPortal.subdomain,
    protocol: window.location.protocol,
    appDomain: window.env.APPLICATION_DOMAIN,
    documentUploadId: documentUpload.id,
  });

  const onSubmit = (formData: ShareFileFormStructure) => {
    track("Emailed Link", {
      documentUploadId: documentUpload.id,
      logCreated: formData.createLog,
      userCCd: formData.copyYourself,
    });

    void shareDocumentUpload({
      variables: {
        data: {
          documentUploadId: documentUpload.id,
          recipientName: formData.name,
          recipientEmail: formData.email,
          recipientType: formData.role,
          message: formData.message,
          copyYourself: formData.copyYourself,
          createLog: formData.createLog,
        },
      },
    });
  };

  if (showSuccess) {
    return (
      <SuccessView
        headerText="Share file"
        recipientEmail={getValues("email")}
        residentLogId={residentLogId}
        onClose={onCancel}
      />
    );
  }

  return (
    <Container>
      <HeaderSection>
        <h1>Share file</h1>
        <h2>{documentUpload.originalFilename}</h2>
      </HeaderSection>
      <ContentSection>
        <FlexColumn style={{ gap: "16px" }}>
          <FlexRow style={{ gap: "16px" }}>
            <Text
              {...register("name", { required: "Name is required" })}
              label="Name"
              error={errors.name?.message}
              required
            />
            <Select
              control={control}
              name="role"
              label="Role"
              error={errors.role?.message}
              rules={{ required: "Role is required" }}
              required
              options={CONTACT_TYPE_OPTIONS}
            />
          </FlexRow>
          <Text
            {...register("email", {
              required: "Email is required",
              validate: val => {
                if (!isValidEmail(val)) {
                  return "Please provide a valid email address";
                }
                return;
              },
            })}
            label="Email address"
            error={errors.email?.message}
            required
          />
          <Textarea
            {...register("message")}
            label="Message"
            error={errors.message?.message}
          />
          <FlexRow style={{ gap: "8px", alignItems: "center" }}>
            <Checkbox {...register("copyYourself")} id="copyYourself" />
            <label htmlFor="copyYourself">
              Copy yourself on email ({user?.email ?? admin?.email})
            </label>
          </FlexRow>
          {!!documentUpload.property?.id && (
            <FlexRow style={{ gap: "8px", alignItems: "center" }}>
              <Checkbox {...register("createLog")} id="createLog" />
              <label htmlFor="createLog">Create new log</label>
            </FlexRow>
          )}
        </FlexColumn>
      </ContentSection>
      <ButtonSection>
        <SecondaryButtons>
          <Button
            styleVariant="ghost"
            size="small"
            leftIconName="link"
            onClick={async () => {
              track("Copied Link", { documentUploadId: documentUpload.id });

              await clipboard.writeText(fileURL);
              addSuccessToast("Link copied to clipboard");
            }}
          >
            Copy public link
          </Button>
        </SecondaryButtons>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            styleVariant="primary"
            size="small"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            Send
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
